window.addEventListener('scroll', function () {
    var nav = document.querySelector('nav');
    var logoDesktop = document.querySelector('.logo img');
    var logoMobile = document.querySelector('.logomobile img');
    var scrollPosition = window.scrollY || window.pageYOffset;

    // Zmieniamy klasę nawigacji w zależności od przewinięcia
    if (scrollPosition > 50) { // Możesz dostosować tę wartość
        nav.classList.add('navbar-light');
        nav.classList.remove('navbar-dark'); // Załóżmy, że domyślnie używasz klasy 'navbar-dark'
        
        // Zmieniamy logo na kolorowe, jeśli przewinięto stronę
        if (logoDesktop) {
            logoDesktop.src = "/assets/img/logo-rybka_h120.png"; 
        }
        if (logoMobile) {
            logoMobile.src = "/assets/img/logo-rybka_h120.png"; 
        }
    } else {
        nav.classList.add('navbar-dark');
        nav.classList.remove('navbar-light');
        
        // Zmieniamy logo na białe, jeśli strona nie jest przewinięta
        if (logoDesktop) {
            logoDesktop.src = "/assets/img/logo-rybka_h120_white.png"; 
        }
        if (logoMobile) {
            logoMobile.src = "/assets/img/logo-rybka_h120_white.png"; 
        }
    }
});
